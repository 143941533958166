<template>
  <div class="carriers-container">
    <div class="" v-if="!isLoading">
      <template v-if="progressBarValue < 100">
        <FadeAnimationTextArray :textArray="loadingTextArray" />
        <div class="progress-bar-container">
          <b-progress :max="progressBarMax" animated>
            <b-progress-bar
              :value="progressBarValue"
              :label="`${progressBarValue}%`"
            ></b-progress-bar>
          </b-progress>
        </div>
      </template>
      <div
        class="filtering-checkboxes"
        v-if="notificationsPerMode === notificationCounter || timeOutFinished"
      >
        <div class="filtering-checkboxes__checkbox" v-if="showCarriers && showTrucks">
          <el-switch
            v-model="activeCompareByMode"
            :disabled="!this.carriersAndTrucks.length"
          >
          </el-switch>
          <span class="filtering-checkboxes__text">Compare By Mode</span>
        </div>
        <div
          class="filtering-checkboxes__checkbox"
          v-if="showCarriers && showEasyPostFilter"
        >
          <el-switch v-model="onlyEasypost"> </el-switch>
          <span class="filtering-checkboxes__text">Parcel only</span>
        </div>
        <div class="filtering-checkboxes__checkbox">
          <el-switch
            v-model="guaranteedFilter"
            :disabled="!this.carriersAndTrucks.length"
            @change="guaranteedFilterHandler"
          >
          </el-switch>
          <span class="filtering-checkboxes__text"
            >Guaranteed only
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              class="bi bi-lightning-fill"
              viewBox="0 0 16 16"
              fill="#FF9900"
            >
              <path
                d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"
              /></svg
          ></span>
        </div>
        <div class="filtering-checkboxes__checkbox">
          <el-switch
            v-model="favoriteCarriersFilter"
            :disabled="!this.carriersAndTrucks.length"
            @change="favoriteCarriersFilterHandler"
          >
          </el-switch>
          <span class="filtering-checkboxes__text"
            >My favorite carriers
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              class="bi bi-star-fill"
              viewBox="0 0 16 16"
              fill="#FF9900"
            >
              <path
                d="M8 0 l1.6 5.2 h5.2l-4 3.2 1.6 5.2-4-3.2-4 3.2 1.6-5.2-4-3.2h5.2z"
              />
            </svg>
          </span>
        </div>
      </div>
      <div v-if="!activeCompareByMode">
        <span class="divider"></span>
        <CarriersAndTrucks
          :carriers="carriersAndTrucks"
          :originalCarrierArray="originalCarrierArray"
          :allNotificationsObtained="
            notificationsPerMode === notificationCounter || timeOutFinished
          "
          :filterByGuaranteed="guaranteedFilter"
          :favoriteCarriersFilter="favoriteCarriersFilter"
        />
      </div>
      <div v-else-if="onlyEasypost">
        <EasypostSection
          :carriers="easyPostCarriers"
          :originalCarrierArray="originalCarrierArray"
          :allNotificationsObtained="
            notificationsPerMode === notificationCounter || timeOutFinished
          "
          :filterByGuaranteed="guaranteedFilter"
          :favoriteCarriersFilter="favoriteCarriersFilter"
        />
      </div>
      <div v-else>
        <CarrierMessage v-if="carriers.length !== 0 && !isBranded" />
        <template v-if="showPartial && partial.length > 0 && promotePartialSection">
          <Partial
            :partial="partial"
            :allNotificationsObtained="
              notificationsPerMode === notificationCounter || timeOutFinished
            "
            :filterByGuaranteed="guaranteedFilter"
          />
        </template>
        <template v-if="showCarriers">
          <Carriers
            :carriers="carriers"
            :originalCarrierArray="originalCarrierArray"
            :allNotificationsObtained="
              notificationsPerMode === notificationCounter || timeOutFinished
            "
            :filterByGuaranteed="guaranteedFilter"
            :favoriteCarriersFilter="favoriteCarriersFilter"
          />
        </template>
        <template v-if="showTrucks">
          <span class="divider"></span>
          <Trucks
            :trucks="trucks"
            :carriers="carriers"
            :allNotificationsObtained="
              notificationsPerMode === notificationCounter || timeOutFinished
            "
            :filterByGuaranteed="guaranteedFilter"
          />
        </template>
        <template v-if="showPartial && partial.length > 0 && !promotePartialSection">
          <Partial
            :partial="partial"
            :allNotificationsObtained="
              notificationsPerMode === notificationCounter || timeOutFinished
            "
            :filterByGuaranteed="guaranteedFilter"
          />
        </template>
        <template v-if="modesDispatch['reefer'].enabled && showReefer">
          <Reefer
            :reefer="reefer"
            :allNotificationsObtained="
              notificationsPerMode === notificationCounter || timeOutFinished
            "
            :filterByGuaranteed="guaranteedFilter"
          />
        </template>
        <template v-if="modesDispatch['flatbed'].enabled && showFlatbed">
          <Flatbed
            :flatbed="flatbed"
            :allNotificationsObtained="
              notificationsPerMode === notificationCounter || timeOutFinished
            "
            :filterByGuaranteed="guaranteedFilter"
          />
        </template>
      </div>
    </div>
    <TrucksListSkeleton v-if="isLoading" />
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import formatterCarrierName from "@/Extend/FormatCarrierName";
import FilterEasyPostCarriers from "@/Extend/FilterEasyPostCarriers";
import Trucks from "./components/Sections/Trucks/Trucks.vue";
import Carriers from "./components/Sections/Carriers/Carriers.vue";
import TrucksListSkeleton from "./components/TrucksListSkeleton.vue";
import modes from "../../modes";
import FadeAnimationTextArray from "@/components/FadeAnimationTextArray.vue";
import LoadingTextArray from "@/utils/LoadingTextArray";
import CarriersAndTrucks from "./components/CarriersAndTrucks.vue";
import Partial from "./components/Sections/Partial/Partial.vue";
import Reefer from "./components/Sections/Reefer/Reefer.vue";
import Flatbed from "./components/Sections/Flatbed/Flatbed.vue";
import CarrierMessage from "./components/CarrierMessage.vue";
import EasypostSection from "./components/Sections/EasypostSection.vue";

export default {
  name: "CarriersList",
  mixins: [formatterCarrierName, FilterEasyPostCarriers],
  props: {
    chosenLoad: Object,
  },
  components: {
    Trucks,
    Carriers,
    TrucksListSkeleton,
    FadeAnimationTextArray,
    CarriersAndTrucks,
    Partial,
    Reefer,
    Flatbed,
    CarrierMessage,
    EasypostSection,
  },
  data() {
    return {
      value1: false,
      carriers: [],
      trucks: [],
      partial: [],
      reefer: [],
      flatbed: [],
      carriersAndTrucks: [],
      truckTypes: null,
      isLoading: true,
      areThereCarriers: true,
      progressBarValue: 10,
      progressBarMax: 100,
      notificationCounter: 0,
      notificationsPerMode: 0,
      percentageStep: 0,
      showCarriers: false,
      showTrucks: false,
      showPartial: false,
      showReefer: false,
      showFlatbed: false,
      loadingTextArray: LoadingTextArray.quoteBook,
      activeCompareByMode: false,
      guaranteedFilter: false,
      favoriteCarriersFilter: false,
      favoriteCarriers: [],
      timeOutFinished: false,
      modesDispatch: [],
      promotePartialSection: false,
      isBranded: false,
      onlyEasypost: false,
      showEasyPostFilter: false,
      easyPostCarriers: [],
      originalCarrierArray: [],
    };
  },
  async created() {
    this.modesDispatch = modes;
    this.favoriteCarriers = JSON.parse(
      localStorage.getItem("user-settings")
    ).favorite_carriers;
    this.isBranded = this.$store.getters["login/getTokenInfo"].is_branded;
    if (this.$route.params.requote) {
      this.initNotificationsConfig();
      this.requoteLoad();
      this.getCarriersAfterLoadCreation();
    } else if (this.$route.params.socket) {
      this.initNotificationsConfig();
      this.getCarriersAfterLoadCreation();
    } else {
      this.progressBarValue = 100;
      this.showCarriersViaHttp();
    }
    this.setTransportModalInfo();
  },
  computed: {
    ...mapGetters({
      socketResponse: "carriers/getCarriersSocket",
      carriersAlerts: "carriersAlert/getAllCarriersAlert",
      appliedMarkups: "load/getAppliedMarkups",
    }),
  },
  watch: {
    socketResponse(socketResponse) {
      if (socketResponse.data.load_id !== this.$route.params.loadId) {
        return;
      }
      this.notificationCounter += 1;
      if (this.notificationCounter === 1) {
        this.progressBarValue = 0;
      }
      // Provisional solution when business rules are not valid
      if (socketResponse.data.errors) {
        this.$store.commit("carriers/disconnectSocket");
        this.areThereCarriers = false;
        this.modifyProgressBarValue(this.notificationsPerMode);
        return;
      }
      if (socketResponse.notification === "new-quote") {
        const timeoutDuration = 90000;
        let timeoutId;
        const handleTimeout = () => {
          this.$store.commit("carriers/disconnectSocket");
          this.progressBarValue = 100;
          this.validateNotificationsLimitAndDisconnect();
          const haveCarriers = this.disabledButtonsValidation();
          this.$emit("input", !haveCarriers);
          this.timeOutFinished = true;
        };
        if (this.notificationsPerMode !== this.notificationCounter) {
          timeoutId = setTimeout(handleTimeout, timeoutDuration);
        }
        this.validateNotificationsLimitAndDisconnect();
        this.carriersNotificationHandler(socketResponse.data.carriers, timeoutId);
        this.modifyProgressBarValue(this.notificationCounter);
      }
    },
    carriersAndTrucks() {
      const haveCarriers = this.disabledButtonsValidation();
      if (haveCarriers && this.notificationCounter === this.notificationsPerMode) {
        this.$emit("input", false);
      } else {
        this.$emit("input", true);
      }
    },
    progressBarValue: {
      immediate: true,
      handler(value) {
        if (value >= 100 && this.$route.params.socket) {
          this.promotePartialSection = this.validatePricePartialWithLTL(
            this.carriers,
            this.partial
          );
        }
      },
    },
    activeCompareByMode(newValue) {
      if (newValue) {
        this.showSkeletonForDuration();
      }
    },
  },
  methods: {
    showSkeletonForDuration() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    disabledButtonsValidation() {
      if (
        this.carriers.length ||
        this.trucks.length ||
        this.flatbed.length ||
        this.reefer.length
      ) {
        return true;
      } else {
        return false;
      }
    },
    // Notifications handling
    initNotificationsConfig() {
      this.setNotificationsQuantityPerMode();
      this.percentageStep = this.progressBarMax / this.notificationsPerMode;
    },
    setNotificationsQuantityPerMode() {
      const modeId = this.chosenLoad.mode_id;
      const modesKeys = Object.keys(modes).reverse();
      const isMultiservice = this.checkIfLoadIsMultiservice();
      if (isMultiservice) {
        const multiserviceMode = modes[modesKeys.find((key) => modes[key].multiservice)];
        this.notificationsPerMode = multiserviceMode.notificationsNumber;
        return;
      }
      const modeName = modesKeys.find((key) => modes[key].modeId === modeId);
      this.notificationsPerMode = modes[modeName].notificationsNumber;
    },
    carriersNotificationHandler(carriersData) {
      const socketResponse = JSON.parse(carriersData);
      this.originalCarrierArray.push(...socketResponse);
      const filteredEasyPostCarriers = this.filterAndMergeEasyPostCarriers(
        this.originalCarrierArray
      );
      this.buildTrucksAndCarriersList(filteredEasyPostCarriers);
    },
    validateNotificationsLimitAndDisconnect() {
      if (this.notificationsPerMode === this.notificationCounter) {
        this.$store.commit("carriers/disconnectSocket");
        this.areThereCarriers = false;
        if (this.carriers.length > 0 || this.trucks.length > 0) {
          this.$emit("input", false);
        }
      }
    },
    async getCarriersAfterLoadCreation() {
      const isMultiservice = this.checkIfLoadIsMultiservice();
      this.buildDefaultData(isMultiservice);
      this.joinToCarriersSocket();
    },
    async showCarriersViaHttp() {
      if (this.$options.filters.checkPermission("get:carriers")) {
        const carriersAndTrucks = await this.getAllOptionsCarriers();
        // When isMultiservice is true, it means that there must be both carriers and trucks.
        const isMultiservice = this.checkIfLoadIsMultiservice();
        this.buildDefaultData(isMultiservice);
        this.originalCarrierArray = carriersAndTrucks.carriers;
        const filteredEasyPostCarriers = this.filterAndMergeEasyPostCarriers(
          this.originalCarrierArray
        );
        this.buildTrucksAndCarriersList(filteredEasyPostCarriers);
        if (this.carriers.length) {
          this.$emit("input", false);
          this.areThereCarriers = true;
        } else {
          this.areThereCarriers = false;
        }
        this.promotePartialSection = this.validatePricePartialWithLTL(
          this.carriers,
          this.partial
        );
        this.isLoading = false;
      }
    },
    buildTrucksAndCarriersList(newCarriers) {
      let carriers = this.addThresholdToCarriers(newCarriers);
      this.carriers = [
        ...this.carriers,
        ...carriers
          .filter(
            (carrier) =>
              !this.validateRepeatedCarrier(this.carriers, carrier) &&
              !this.isTheCarrierATruck(carrier) &&
              !this.isPartialCarrier(carrier) &&
              !this.isReeferCarrier(carrier) &&
              !this.isFlatbedCarrier(carrier)
          )
          .map(this.setFavStatusToCarrier),
      ];
      // this.originalCarrierArray = this.carriers;
      this.partial = [
        ...this.partial,
        ...carriers.filter(
          (carrier) =>
            !this.validateRepeatedCarrier(this.partial, carrier) &&
            this.isPartialCarrier(carrier)
        ),
      ];
      this.trucks = [
        ...this.trucks,
        ...carriers.filter(
          (carrier) =>
            !this.validateRepeatedCarrier(this.trucks, carrier) &&
            this.isTheCarrierATruck(carrier) &&
            !this.isReeferCarrier(carrier) &&
            !this.isFlatbedCarrier(carrier)
        ),
      ];
      this.reefer = [
        ...this.reefer,
        ...carriers.filter(
          (carrier) =>
            !this.validateRepeatedCarrier(this.reefer, carrier) &&
            this.isReeferCarrier(carrier)
        ),
      ];
      this.flatbed = [
        ...this.flatbed,
        ...carriers.filter(
          (carrier) =>
            !this.validateRepeatedCarrier(this.flatbed, carrier) &&
            this.isFlatbedCarrier(carrier)
        ),
      ];
      this.carriersAndTrucks = [
        ...this.carriers,
        ...this.trucks,
        ...this.partial,
        ...this.reefer,
        ...this.flatbed,
      ];
      this.showEasyPostFilter = this.validateEasyPostCarriers(this.carriers);
      if (this.showEasyPostFilter) {
        this.easyPostCarriers = this.carriers.filter(
          (carrier) =>
            carrier.source === "easy-post" &&
            (carrier.scac === "UPSN" || carrier.scac === "FDEG")
        );
      }
    },
    validatePricePartialWithLTL(carriers, partial) {
      const minCarrierPrice = Math.min(...carriers.map((carrier) => carrier.price));
      const minPartialPrice = Math.min(...partial.map((carrier) => carrier.price));
      if (minPartialPrice < minCarrierPrice) {
        return true;
      }
      return false;
    },
    validateEasyPostCarriers(carriers) {
      return carriers.some((carrier) => carrier.source.toLowerCase() === "easy-post");
    },
    checkIfLoadIsMultiservice() {
      return "multiservice" in this.chosenLoad
        ? this.chosenLoad.multiservice
        : this.chosenLoad.load.multiservice;
    },
    joinToCarriersSocket() {
      this.$store.dispatch("carriers/joinToCarriersSocket", this.$route.params.loadId);
      this.isLoading = false;
    },
    buildDefaultData(isMultiservice) {
      // This method is used to set the default data based on the load mode
      if (isMultiservice) {
        this.showCarriers = true;
        this.showTrucks = true;
        this.showPartial = true;
        this.showReefer = true;
        this.showFlatbed = true;
        return;
      }
      const chosenModeInfo = Object.values(modes).find(
        (mode) => mode.modeId === this.chosenLoad.mode_id && !mode.multiservice
      );
      if (chosenModeInfo) {
        this.showCarriers = chosenModeInfo.carriers;
        this.showTrucks = chosenModeInfo.trucks;
        this.showPartial = chosenModeInfo.partial;
        this.showReefer = chosenModeInfo.reefer;
        this.showFlatbed = chosenModeInfo.flatbed;
        return;
      }
      this.showCarriers = true;
    },
    resetLists() {
      this.carriers = [];
      this.trucks = [];
    },
    async getAllOptionsCarriers() {
      const carriers = await this.$store.dispatch(
        "carriers/getAllOptionsCarriers",
        this.$route.params.loadId
      );
      return carriers;
    },
    setTransportModalInfo() {
      this.$store.commit("transportSelected/modifyTransportModalInformation", {
        key: "accesorials",
        value: this.chosenLoad.accessorials,
      });
      this.$store.commit("transportSelected/modifyTransportModalInformation", {
        key: "isInsurance",
        value: this.chosenLoad.insurance_required,
      });
      this.$store.commit("transportSelected/modifyTransportModalInformation", {
        key: "insurance",
        value: this.chosenLoad.cargo_value_for_insurance,
      });
    },
    async getTruckTypes() {
      this.truckTypes = await this.$store.dispatch("truckTypes/getAllTruckTypes");
    },
    async requoteLoad() {
      const data = {
        id: this.$route.params.loadId,
      };
      this.$store.dispatch("load/requoteLoad", data);
    },
    modifyProgressBarValue() {
      this.progressBarValue += Math.ceil(this.percentageStep);
    },
    addThresholdToCarriers(carriersList) {
      const carriersWithThresholds = carriersList.map((item) => {
        const carrierThreshold = this.carriersAlerts.find((alert) =>
          alert.carrier_name.toLowerCase().includes(item.carrier.toLowerCase())
        );
        if (!carrierThreshold) {
          const description = item.thresholds;
          return {
            ...item,
            description: description,
            alert: description && description.length > 0,
          };
        } else {
          const description = item.thresholds || carrierThreshold.description.en;
          return {
            ...item,
            description,
            alert: description.length > 0,
          };
        }
      });
      return carriersWithThresholds;
    },
    validateRepeatedCarrier(carriers, carrier) {
      return carriers.some((element) => element._id === carrier._id);
    },
    isPartialCarrier(carrier) {
      const partialSource = "partial";
      return carrier.source.toLowerCase() === partialSource;
    },
    isTheCarrierATruck(carrier) {
      const trucksSourcesList = ["otr", "athenea", "flatbed", "reefer"];
      return trucksSourcesList.includes(carrier.source.toLowerCase());
    },
    isReeferCarrier(carrier) {
      const reeferSource = "reefer";
      return carrier.source.toLowerCase() === reeferSource;
    },
    isFlatbedCarrier(carrier) {
      const reeferSource = "flatbed";
      return carrier.source.toLowerCase() === reeferSource;
    },
    setFavStatusToCarrier(carrier) {
      return {
        ...carrier,
        fav: this.favoriteCarriers.includes(carrier.carrier),
      };
    },
    getMultiserviceModesQuoted() {
      const allCarriers = [...this.carriers, ...this.trucks];
      let multiserviceModesQuoted = "";
      if (allCarriers.some((carrier) => carrier.source.toLowerCase() === "athenea")) {
        multiserviceModesQuoted += "EXPEDITED;";
      }
      if (allCarriers.some((carrier) => carrier.source.toLowerCase() === "otr")) {
        multiserviceModesQuoted += "FTL - DRY;";
      }
      if (allCarriers.some((carrier) => carrier.source.toLowerCase() === "easy-post")) {
        multiserviceModesQuoted += "Parcel;";
      }
      if (
        allCarriers.some((carrier) =>
          ["revenova", "stored-rates", "stored_rates"].some((item) =>
            item.includes(carrier.source.toLowerCase())
          )
        )
      ) {
        multiserviceModesQuoted += "LTL;";
      }
      return multiserviceModesQuoted.substring(0, multiserviceModesQuoted.length - 1);
    },
    favoriteCarriersFilterHandler(value) {
      if (value) {
        this.guaranteedFilter = false;
      }
      this.showLoadingOnfilterAction();
    },
    guaranteedFilterHandler(value) {
      if (value) {
        this.favoriteCarriersFilter = false;
      }
      this.showLoadingOnfilterAction();
    },
    async showLoadingOnfilterAction() {
      this.isLoading = true;
      await this.sleep(50);
      this.isLoading = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/CheckboxesByFilter.scss";
.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 640px) {
    margin: 20px auto;
  }
}
::v-deep {
  .progress {
    height: 15px;
    background-color: #dbdfe3;
    width: 70%;
    @media (max-width: 640px) {
      width: 90%;
    }
  }
  .progress-bar {
    background-color: $color-progress-bar;
  }
}

.filtering-checkboxes {
  display: flex;
  position: absolute;
  gap: 10px;
  @media (min-width: 1200px) {
    margin-left: 10px;
  }
  @media (max-width: 805px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  &__checkbox {
    display: flex;
    justify-content: flex-end;
  }
  &__text {
    @include font-small-button-text;
    margin-left: 5px;
    text-align: left;
    padding-right: 5px;
  }
}

@media (max-width: 610px) {
  ::v-deep .carriers-list__sortby {
    margin-bottom: 25px;
  }
}

::v-deep .el-switch__core {
  height: 21px;
}
::v-deep .el-switch__core:after {
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 100%;
  transition: all 0.3s;
  width: 15px;
  height: 15px;
  background: linear-gradient(
    90deg,
    var(--color-border-container),
    var(--color-primary-company),
    var(--color-primary-company-lighten),
    var(--color-primary-trigger-button-hover)
  );
  background-size: 400%;
  animation: colorChange 10s linear infinite;
  border: 1px solid #fff;
}

::v-deep .el-switch.is-checked .el-switch__core {
  background: linear-gradient(
    90deg,
    var(--color-border-container),
    var(--color-primary-company),
    var(--color-primary-company-lighten),
    var(--color-primary-trigger-button-hover)
  );
  background-size: 400%;
  animation: colorChange 10s linear infinite;
  border-color: #00b9cc;
}

@keyframes colorChange {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

@media (max-width: 800px) {
  .carriers-container {
    position: relative;
    margin-top: 50px;
  }
  .filtering-checkboxes {
    top: -40px;
  }
}
</style>
