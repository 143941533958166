<template>
  <div class="info-about-selected-carrier">
    <div class="documents-information">
      <span> {{ $t("carriers.modalInfo.selectedCarrier") }} </span>
      : <br />
      <div v-if="!insurance" class="documents-information__text">
        <p v-if="!isPickup">USD ${{ formatEasyPostPriceWithoutPickup(carrier) }} </p>
        <p v-else>USD ${{ formatEasyPostPriceWithPickup(carrier, carrier.quoteContractId) }} </p>
      </div>
      <div class="documents-information__text" v-else>
        <p v-if="!isPickup">
          USD ${{ formatEasyPostPriceWithoutPickup(carrier) + aditionalInsurance }}
        </p>
        <p v-else>
          USD ${{
            formatEasyPostPriceWithPickup(carrier) + aditionalInsurance
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FormatEasyPostPrices from "../../../../../../Extend/FormatEasyPostPrices";

export default {
  name: "InfoAboutSelectedCarrier",
  mixins: [FormatEasyPostPrices],
  props: {
    insurance: Boolean,
    transportSelected: Object,
    carrier: Object,
    aditionalInsurance: Number,
    isPickup: Boolean,
    originalCarrierArray: Array
  },
};
</script>

<style lang="scss" scoped>
.info-about-selected-carrier {
  span {
    font-weight: bold;
  }
  p {
    margin: 0px;
  }
}
</style>
