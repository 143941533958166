export default {
  props: {
    lineItems: Array,
  },
  methods: {
    formatEasyPostPriceWithoutPickup(carrier) {
      const { price } = carrier;
      return price;
    },
    formatEasyPostPriceWithPickup(carrier) {
      const { quoteContractId, source } = carrier;
      if (source !== 'easy-post') {
        return carrier.price;
      }
      let carrierWithDefaultPickup;
      const quote = this.originalCarrierArray.find((item) => item.quoteContractId === quoteContractId &&
        Array.isArray(item.accesorials) &&
        item.accesorials.some((accessorial) => accessorial?.Name?.toLowerCase() === 'parcel pick up fee'));
      if (!quote) {
        carrierWithDefaultPickup = this.originalCarrierArray.find((item) => item.quoteContractId === quoteContractId);
        return carrierWithDefaultPickup.price;
      }
      return quote.price;
    },
  },
};
