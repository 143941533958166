<template>
  <div class="table-row-content">
    <div class="table-rows-container">
      <div v-for="(carriersQuotes, index) in filteredCarrierData" :key="index">
        <div :class="carriersQuotes.principalCarrier ? 'quoted' : 'table-row-list'">
          <div class="table-row-list__item">
            <p>{{ carriersQuotes.carrier }}</p>
          </div>
          <div class="table-row-list__item">
            <p>{{ carriersQuotes.serviceClass }}</p>
          </div>
          <div class="table-row-list__item">
            <p>{{ carriersQuotes.transitTime }}</p>
          </div>
          <div class="table-row-list__item">
            <p>{{ carriersQuotes.expiration }}</p>
          </div>
          <div class="table-row-list__item" v-if="!validateIfIsEasypost(carriersQuotes)">
            <p>{{ carriersQuotes.price | priceToUsd }}</p>
          </div>
          <div class="table-row-list__item" v-if="validateIfIsEasypost(carriersQuotes)">
            <p><strong>Pickup:</strong> {{ formatEasyPostPriceWithPickup(carriersQuotes) | priceToUsd }}</p>
            <p><strong>Drop off:</strong> {{ formatEasyPostPriceWithoutPickup(carriersQuotes) | priceToUsd }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormatEasyPostPrices from '../../../Extend/FormatEasyPostPrices';
import FilterEasyPostCarriers from "../../../Extend/FilterEasyPostCarriers";

export default {
  name: "TableRowContentCarriersQuote",
  mixins: [FormatEasyPostPrices, FilterEasyPostCarriers],
  props: {
    dataCarriers: Array,
  },
  data() {
    return {
      filteredCarrierData: null,
      originalCarrierArray: null
    };
  },
  created() {
    this.originalCarrierArray = this.dataCarriers;
    this.filteredCarrierData = this.filterAndMergeEasyPostCarriers(this.dataCarriers);
  },
  computed: {
    validateIfIsEasypost() {
      return (carrier) => {
        const scacList = ["USPS", "UPSN", "FDEG"];
        if (carrier.scac && scacList.includes(carrier.scac) && carrier.source === "easy-post") {
          return true;
        }
        return false;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-row-content {
  padding: 0px 15px;
}
.table-rows-container {
  padding: 0px 1rem;
  width: 100%;
  height: calc(100% - 50px);
}
.table-row-list {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 80px;
  padding: 10px 0px;
  color: $color-primary-company;
  border-bottom: 1px solid $color-border-container;
  border-top: none;
  &__item {
    width: 25%;
    p {
      word-break: break-all;
      margin: 0px;
    }
  }
}

.quoted {
  @extend .table-row-list;
  background-color: #15486f2e;
  position: relative;
}

.quoted:hover::after {
  content: "This carrier is the carrier QUOTED";
  display: block;
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 3px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
</style>
