<template>
  <div class="quote-acceptance-modal">
    <b-overlay :show="loading" rounded="sm">
      <div class="quote-acceptance-content">
        <div class="quote-acceptance__header">
          <div class="quote-acceptance__title">
            <h3>{{ $t("carriers.modalInfo.quoteAcceptance") }}</h3>
          </div>
          <div class="quote-acceptance__button">
            <button @click="closeModal" class="close-button">
              <i class="ion ion-close-circle-outline close-button__icon"></i>
            </button>
          </div>
        </div>
        <hr class="mb-2" />
        <div class="quote-acceptance__body">
          <div class="quote-acceptance__body-item">
            <InfoAboutSelectedCarrier
              :insurance="insurance"
              :carrier="carrier"
              :transportSelected="transportSelected"
              :isPickup="isPickup"
              :originalCarrierArray="originalCarrierArray"
              :aditionalInsurance="aditionalInsurance"
              :lineItems="lineItems"
            />
          </div>
          <div class="quote-acceptance__body-item">
            <el-collapse v-model="accessorialsTab">
              <el-collapse-item title="Accessorials" name="accessorials">
                <AccessorialsByType :accessorials="transportModalInfo.accesorials" />
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="quote-acceptance__body-item">
            <PortalNotes :note="transportModalInfo.portalNotes" />
          </div>
          <div class="quote-acceptance__body-item" v-if="insurance">
            <InfoAboutInsurance :transportModalInfo="transportModalInfo" />
            <p class="mt-1">
              Commodities such as electronics, aircraft parts, fragile cargo, HHG, artworks,
              watches, motor vehicles, machinery and heavy equipment won´t fall under the general
              cargo category so we will need to requote accordingly. Also, shipments going to Mexico
              no matter the commodity, will need to be re-quoted as well.
            </p>
            <b><i>{{ $t("carriers.messageVendorInsurance") }} </i></b>
          </div>
          <div
            class="quote-acceptance__body-item"
            v-if="
              !this.tokenInfo.magaya_guid &&
              !this.tokenInfo.is_branded &&
              insurance &&
              (loyaltyTier == 'Diamond' || loyaltyTier == 'Diamond Plus')
            "
          >
            <ClaimBenefits v-model="enableLoyaltyInsurance" />
          </div>
          <div class="quote-acceptance__body-item" v-if="!tokenInfo.is_branded">
            <TermsAndConditionsVue />
          </div>
          <div class="quote-acceptance__body-item" v-if="showRecommendInsuranceAlert">
            <AlertInsuranceRecommend />
          </div>
           <div class="checkbox-container">
            <b-form-checkbox id="terms" v-model="confirmTermsAndConditions"/>
            <label for="terms">I have read and agreed to the Terms and Conditions</label>
           </div>
          <div class="quote-acceptance__body-item">
            <QuoteAcceptanceButtons
              :carrier="carrier"
              :lineItems="lineItems"
              :aditionalInsurance="aditionalInsurance"
              :isDropOffPickup="showPickupPrice"
              :isInsurance="insurance"
              :isInsuranceRecommend="transportSelected.insuranceAlert"
              :confirmTermsAndConditions="confirmTermsAndConditions"
              :originalCarrierArray="originalCarrierArray"
              @continueToTendering="continueToTendering"
            />
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Checkbox from "primevue/checkbox";
import AccessorialsByType from "../../../../../components/AccessorialsByType.vue";
import TermsAndConditionsVue from "../../../shared/TermsAndConditions.vue";
import FormatEasyPostPrices from "../../../../../Extend/FormatEasyPostPrices";
import QuoteAcceptanceButtons from "./QuoteAcceptanceButtons.vue";
import AlertInsuranceRecommend from "./QuoteAcceptanceModal/AlertInsuranceRecommend.vue";
import InfoAboutSelectedCarrier from "./QuoteAcceptanceModal/InfoAboutSelectedCarrier.vue";
import InfoAboutInsurance from "./QuoteAcceptanceModal/InfoAboutInsurance.vue";
import ClaimBenefits from "./QuoteAcceptanceModal/ClaimBenefits.vue";
import PortalNotes from "./QuoteAcceptanceModal/PortalNotes.vue";

export default {
  name: "QuoteAcceptanceModal",
  components: {
    AccessorialsByType,
    TermsAndConditionsVue,
    QuoteAcceptanceButtons,
    AlertInsuranceRecommend,
    InfoAboutSelectedCarrier,
    InfoAboutInsurance,
    ClaimBenefits,
    PortalNotes,
    // Checkbox
  },
  mixins: [FormatEasyPostPrices],
  props: {
    insurance: Boolean,
    aditionalInsurance: Number,
    showPickupPrice: Boolean,
    carrier: Object,
    lineItems: Array,
    originalCarrierArray: Array,
    isPickup: Boolean
  },
  data() {
    return {
      loading: false,
      accessorialsTab: ["accessorials"],
      transportSelected: {
        name: null,
        price: null,
        quoteNumber: null,
        portalNotes: null,
        insuranceAlert: null,
      },
      enableLoyaltyInsurance: false,
      tokenInfo: {},
      confirmTermsAndConditions: false
    };
  },
  async created() {
    this.loyaltyTier = this.$store.getters["load/getChosenLoad"].loyalty_tier;
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
    this.transportSelected.name = this.transportModalInfo.selectedTransport.carrier;
    this.transportSelected.portalNotes = this.transportModalInfo.selectedTransport.portalNotes;
    this.transportSelected.insuranceAlert =
      this.transportModalInfo.selectedTransport.insuranceAlert;
    if (this.insurance) {
      // eslint-disable-next-line max-len
      this.transportSelected.price = (
        this.transportModalInfo.selectedTransport.price + this.aditionalInsurance
      ).toFixed(2);
    } else {
      this.transportSelected.price = this.transportModalInfo.selectedTransport.price;
    }
    this.transportSelected.quoteNumber = this.transportModalInfo.selectedTransport.quoteNumber;
  },
  computed: {
    ...mapGetters({
      transportModalInfo: "transportSelected/getTransportModalInformation",
    }),
    showRecommendInsuranceAlert() {
      if (this.transportSelected.insuranceAlert && !this.insurance && !this.showPickupPrice) {
        return true;
      }
      return false;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    continueToTendering({ buyPickup, insurance }) {
      this.$store.commit("carriers/resetCarriersList");
      this.$emit("optionSelected", {
        enableLoyaltyInsurance: this.enableLoyaltyInsurance,
        buyPickup,
        insurance,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Checkboxes.scss";
.close-button {
  @include modals-close-button;
}
.quote-acceptance {
  &__header {
    @include modals-header;
  }
  &-content {
    font-family: $font-family-portal;
  }
  &__selected-carrier {
    @include font-standart-text;
    margin-bottom: 0px;
  }
  &__title {
    @include modals-title;
  }
  &__body {
    display: flex;
    flex-direction: column;
    color: $color-primary-company;
    &-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: start;
      margin: 5px 0px;
      span {
        font-weight: bold;
        margin: 10px 0px;
      }
    }
  }
}
hr {
  margin: 0;
}
::v-deep {
  .el-collapse {
    border: none;
  }
  .el-collapse-item__header {
    color: $color-primary-company;
    background-color: $color-gray-light;
    border: none;
    width: 100%;
    text-align: left;
    font-weight: bold;
    height: 30px;
    font-size: 16px !important;
  }
  .el-icon-arrow-right {
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-collapse-item__content {
    background-color: $color-gray-light;
    @include font-standart-text;
    padding-bottom: 0%;
    border: none;
  }
  .el-collapse-item__wrap {
    border: none;
    background-color: $color-gray-light;
  }
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 10px auto;
  label {
    margin-left: 10px;
    font-size: 15px;
    margin-bottom: 0px;
  }
}
::v-deep .spinner-border {
  color: $color-primary-company;
}
</style>
