<template>
  <div class="insurance-acceptance-modal" v-loading="loading">
    <ul class="insurance-list">
      <li class="insurance-list__item">
        <strong>Selected Insurance</strong>
        <span
          >{{
            selectedInsurance.condition === "Brand New Goods" ? '"All Risk"' : '"TOTAL LOSS"'
          }}
          coverage: {{ selectedInsurance.customer_cost | priceToUsd }}</span
        >
      </li>
      <li class="insurance-list__item">
        <strong>Insured Value</strong>
        <span>$ {{ selectedInsurance.insured_value | insurancePrice }}</span>
      </li>
      <li class="insurance-list__item">
        <strong>Deductible</strong>
        <span>{{ selectedInsurance.deductible }}</span>
      </li>
      <li class="insurance-list__item">
        <strong>Notes</strong>
        <span>This quote is valid for 15 days. <b><i>{{ $t("carriers.messageVendorInsurance") }}</i></b></span>
      </li>
    </ul>
    <div class="aditional-notes">
      <i>
        Please read our service guidelines. By accepting Insurance you agree to be bound by these
        <a
          href="https://storage.googleapis.com/prod-cargocare-certificade/terms-conditions/Terms%20and%20conditions%20Insurance%20Tool.pdf"
          target="_blank"
        >
          terms and conditions.
        </a>
      </i>
    </div>
    <div class="button-container" @click="$emit('changeView', 'GenerateInsuranceCertificate')">
      <button class="button-container__button">Continue</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InsuranceAcceptanceModal",
  props: {
    selectedInsurance: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.insurance-acceptance-modal {
  font-family: $font-family-portal;
}
.insurance-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  &__item {
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    color: $color-primary-company;
  }
}
.aditional-notes {
  margin-top: 10px;
  font-size: 0.9rem;
  color: $color-primary-company;
  a {
    text-decoration: none;
    color: $color-primary-company;
    text-decoration: underline;
  }
}
.button-container {
  display: flex;
  justify-content: center;
  &__button {
    width: 280px;
    margin: 20px 0px 10px 0px;
    border: none;
    color: $color-white;
    background: $color-primary-trigger-button;
    border-radius: 15px;
    height: 35px;
    @include font-small-button-text;
    &:hover {
      background-color: $color-primary-trigger-button-hover;
    }
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}
</style>
