<template>
  <div class="hazmat-info-modal mt-3">
    <p class="hazmat-text">
      The accessorial selected does not cover commodities
      <strong>Hazmat 2.3, Hazmat Explosives 1.4, Hazmat Toxic or Posion 6.1.</strong> or
      <strong>Hazmat Radioactive Yellow II.</strong> If your shipment contains any of those
      commodities, please select the specific accessorial by clicking on MORE ACCESSORIALS.
    </p>
    <div class="button-container">
      <button class="hazmat-button" @click="$emit('closeHazmatModal')">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HazmatInfoModal",
};
</script>

<style lang="scss" scoped>
.hazmat-info-modal {
  font-family: $font-family-portal;
}
.hazmat-text {
  color: $color-primary-company;
  font-weight: normal;
}
.button-container {
  display: flex;
  justify-content: center;
}
.hazmat-button {
  @include status-button($color-save-button, $color-save-button, $color-primary-text-button);
  width: 45%;
}
</style>
