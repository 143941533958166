<template>
  <div class="load-information">
    <div class="load-information__row">
      <div class="description-label">
        <template v-if="tokenInfo.magaya_guid">
          <span class="description-label__name">{{ magayaSellRateTitle }}</span>
          <span
            v-if="loadById.quote_total"
            class="description-label__info description-label__info--light"
          >
            ${{ loadById.quote_total }} {{ magayaSellRate }}
          </span>
        </template>
        <span class="description-label__name">{{ $t("myLoads.myLoadsDetail.quoteTotal") }} </span>
        <div style="display: flex; flex-wrap: wrap" v-if="!tokenInfo.magaya_guid">
          <span v-if="!showCost && showCostBasedOnLoadStatus"> Invoice pending </span>
          <span
            v-else-if="loadById.quote_total"
            class="description-label__info description-label__info--light"
          >
            <label
              v-if="loadById.insurance_required && loadById.cargocare_insurance_selling_rate_amount"
            >
              {{ priceWithInsurance }}
            </label>
            <label v-else-if="loadById.load_status === 'Completed'">
              ${{ loadById.revenue_cost.toFixed(2) }}
            </label>
            <label v-else> ${{ loadById.quote_total.toFixed(2) }} </label>
          </span>
          <i
            v-if="
              loadById.insurance_required &&
              loadById.cargocare_insurance_selling_rate_amount !== undefined &&
              loadById.cargocare_insurance_selling_rate_amount !== null
            "
            v-b-popover.hover="messagePriceWithInsurance"
          >
            <b-icon icon="shield-check" class="icon-insurance"> </b-icon>
          </i>
        </div>
      </div>
      <div class="description-label">
        <span class="description-label__name">
          {{ $t("myLoads.myLoadsDetail.carrier") }}
        </span>
        <span class="description-label__info description-label__info--light">
          {{ loadById.carrier_name }}
        </span>
      </div>
    </div>
    <div class="load-information__row">
      <div class="description-label">
        <span class="description-label__name">
          {{ $t("myLoads.myLoadsDetail.origin") }}
        </span>
        <span class="description-label__info">
          {{ loadById.origin }}
        </span>
      </div>
      <div class="description-label">
        <span class="description-label__name">
          {{ $t("myLoads.myLoadsDetail.destination") }}
        </span>
        <span class="description-label__info">
          {{ loadById.destination }}
        </span>
      </div>
    </div>
    <div class="load-information__row">
      <div class="description-label">
        <span class="description-label__name">
          {{ $t("myLoads.myLoadsDetail.mode") }}
        </span>
        <span class="description-label__info">{{ loadById.mode_name }} </span>
      </div>
      <div class="description-label">
        <span class="description-label__name">
          {{ $t("myLoads.myLoadsDetail.dispatcher") }}
        </span>
        <span class="description-label__info">
          {{ loadById.dispatcher_internal ? loadById.dispatcher_internal.name : "" }}
        </span>
      </div>
    </div>
    <div class="load-information__row">
      <div class="description-label">
        <span class="description-label__name">
          {{ $t("myLoads.myLoadsDetail.billingReference") }}
        </span>
        <div class="description-label__input-container" v-loading="loadingInputs.billing_reference">
          <input
            class="description-label__input"
            type="text"
            v-model="loadInfoValues.billing_reference"
            @blur="updateBillingAndPo('billing_reference')"
            :title="$t('myLoads.myLoadsDetail.billingReference')"
          />
        </div>
      </div>
      <div class="description-label">
        <span class="description-label__name">
          {{ $t("myLoads.myLoadsDetail.po") }}
        </span>
        <div class="description-label__input-container" v-loading="loadingInputs.po_number">
          <input
            class="description-label__input"
            type="text"
            v-model="loadInfoValues.po_number"
            @blur="updateBillingAndPo('po_number')"
            :title="$t('myLoads.myLoadsDetail.po')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import LoadStatusType from "../../../Extend/LoadStatusType";

export default {
  name: "LoadInformation",
  mixins: [LoadStatusType],
  props: {
    loadById: Object,
    showCost: Boolean,
  },
  data() {
    return {
      loadInfoValues: {
        billing_reference: "",
        po_number: "",
      },
      previousPoNumber: null,
      loadingInputs: {
        billing_reference: false,
        po_number: false,
      },
      CargoValueInsurance: 0,
    };
  },
  created() {
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
    this.assignLoadInfoValues();
  },
  computed: {
    magayaSellRateTitle() {
      let magayaSellRateTitle = "Buy rate";
      if (this.loadById.magaya_sell_rate) {
        magayaSellRateTitle = `Buy rate / Sell Rate`;
      }
      return magayaSellRateTitle;
    },
    magayaSellRate() {
      let magayaSellRate;
      if (this.loadById.magaya_sell_rate) {
        magayaSellRate = `/ $${this.loadById.magaya_sell_rate}`;
      }
      return magayaSellRate;
    },
    priceWithInsurance() {
      const valuePrice = this.priceToShowFormat();
      return valuePrice;
    },
    messagePriceWithInsurance() {
      return `${this.$t("myLoads.myLoadsDetail.insuranceCovered")}
      ${this.loadById.cargo_value_for_insurance ? this.$options.filters.priceToUsd(this.loadById.cargo_value_for_insurance) : this.$options.filters.priceToUsd(25000.0)}
      ${this.$t("myLoads.myLoadsDetail.insuranceDelivery")}
      ${this.loadById.cargocare_insurance_selling_rate_amount}
      ${this.$t("myLoads.myLoadsDetail.insuranceIncluded")}
      ${this.$t("carriers.messageVendorInsurance")}`;
    },
    showCostBasedOnLoadStatus() {
      const loadStatus = this.setLoadStatusColor(this.loadById.load_status).label.toLowerCase();
      return loadStatus === "delivered";
    },
  },
  methods: {
    assignLoadInfoValues() {
      const loadAux = JSON.parse(JSON.stringify(this.loadById));
      this.loadInfoValues = {
        billing_reference: loadAux.billing_reference,
        po_number: loadAux.po_number,
      };
    },
    priceToShowFormat() {
      let result = "";
      result = this.loadById.revenue_total;
      if (this.loadById.load_status !== "Completed") {
        result = `$ ${(
          this.loadById.quote_total + this.loadById.cargocare_insurance_selling_rate_amount
        ).toFixed(2)} = ($ ${this.loadById.quote_total.toFixed(2)} + $${
          this.loadById.cargocare_insurance_selling_rate_amount
        })`;
      } else {
        result = `$ ${(this.loadById.revenue_cost).toFixed(2)} = ($ ${(
          this.loadById.revenue_cost - this.loadById.cargocare_insurance_selling_rate_amount
        ).toFixed(2)} + $${this.loadById.cargocare_insurance_selling_rate_amount})`;
      }
      return result;
    },
    async changeView(routeName) {
      await this.$router.push({
        name: routeName,
      });
    },
    async updateBillingAndPo(type) {
      this.loadingInputs[type] = true;
      if (this.loadInfoValues[type] !== this.loadById[type]) {
        await this.$store.dispatch("load/updateBillingPO", {
          id: this.$route.params.loadId,
          body: { [type]: this.loadInfoValues[type] },
        });
        if (type === "po_number") {
          this.validatePoNumber(type);
        }
        this.$store.commit("load/modifyQuoteSpecificData", {
          key: type,
          value: this.loadInfoValues[type],
        });
      }
      this.loadingInputs[type] = false;
    },
    validatePoNumber() {
      if (this.loadInfoValues.po_number !== this.loadById.po_number) {
        this.$emit("handleChangePoNumber", true);
      } else {
        this.$emit("handleChangePoNumber", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.load-information {
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
.description-label {
  min-height: 50px;
  text-align: left;
  margin-bottom: 1rem;
  padding-right: 3%;
  @extend %flex-col;
  width: 50%;
  &__name {
    color: $color-primary-company;
    font-weight: bold;
  }
  &__info {
    color: $color-primary-company;
    font-size: 0.95rem;
    &--light {
      color: $color-border-container;
    }
  }
  &__stops {
    color: $color-gray-dark;
    font-weight: bold;
  }
  &__input-container {
    width: 80%;
    margin-top: 10px;
  }
  &__input {
    width: 100%;
    justify-content: space-between;
    border: 1px solid $color-border-container;
    height: 40px;
    border-radius: 14px;
    padding: 5px;
    outline: none;
  }
}
@media (max-width: 800px) {
  .load-information {
    width: 100%;
    padding: 0 7%;
    button {
      width: 90%;
    }
  }
}

::v-deep {
  .el-loading-mask {
    border-radius: 13px;
  }
}
.icon-insurance {
  color: #00b9cc;
  font-size: 95%;
  margin: 0px 0px 2px 10px;
}
</style>
